import { useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(false);
  const [megabyte, setMegabyte] = useState(0);
  // const [missclick, setMissclick] = useState(false);

  let missclick = false;

  const param = new URLSearchParams(document.location.search);
  // let cost = param.get("cost");

  let external_id = param.get("external_id");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let site_id = param.get("site_id");
  // let pidId = param.get("pid");
  // let subId1 = param.get("sub1");
  // let subId2 = param.get("sub2");
  // let subId3 = param.get("sub3");
  // let subId4 = param.get("sub4");
  // let subId5 = param.get("sub5");
  // let subId6 = param.get("sub6");
  // let subId7 = param.get("sub7");

  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    external_id = localStorage.getItem("creo_id");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    external_id = localStorage.getItem("ad_campaign_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    external_id = localStorage.getItem("site_id");
  }

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(1.67);
    }, 3000);

    setTimeout(() => {
      setMegabyte(2.34);
    }, 4500);

    setTimeout(() => {
      setMegabyte(3.11);
    }, 6000);

    setTimeout(() => {
      setMegabyte(3.63);
    }, 7500);

    setTimeout(() => {
      setMegabyte(4.25);
    }, 9000);

    setTimeout(() => {
      setMegabyte(4.7);
    }, 10500);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 12000);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      window.location.href =
        `https://arumo.top/hPRmm59T?external_id=${external_id}` +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    } else {
      document.querySelector(".App")?.addEventListener("click", () => {
        missclick = true;
      });
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            setAnimating("done");
          }, 14000);

          // async function handleNotifications() {
          //   try {
          //     const permissionResult = await Notification.requestPermission();
          //     if (permissionResult === "granted") {
          //       console.log("Notification permission granted!");
          //       // You can now proceed with sending notifications
          //     } else {
          //       console.log("Notification permission denied");
          //     }
          //   } catch (error) {
          //     console.error("Error requesting notification permission:", error);
          //   }
          //   setAnimating("done");
          // }

          setTimeout(() => {
            document.getElementById("wp-slidedown-allow-button").click();
            // handleNotifications();
          }, 14300);
          // setTimeout(() => {
          //   window.location.href =
          //     `https://axioma.g2afse.com/click?offer_id=722&pid=55` +
          //     (subId1 !== null ? `&sub1=${subId1}` : "") +
          //     (subId2 !== null ? `&sub2=${subId2}` : "") +
          //     (subId3 !== null ? `&sub3=${subId3}` : "") +
          //     (subId4 !== null ? `&sub4=${subId4}` : "") +
          //     (subId5 !== null ? `&sub5=${subId5}` : "") +
          //     (subId6 !== null ? `&sub6=${subId6}` : "") +
          //     (subId7 !== null ? `&sub7=${subId7}` : "");
          // }, 6000);
        })
        .catch(() => {
          if (!missclick) {
            setTimeout(() => {
              window.location.href =
                `https://arumo.top/hPRmm59T?external_id=${external_id}` +
                (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                (ad_campaign_id !== null
                  ? `&ad_campaign_id=${ad_campaign_id}`
                  : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            }, 80);
          } else {
            missclick = false;
          }
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };
  return (
    <div className="App">
      {supported() && !isInstalled() ? (
        <Main
          handleClick={handleClick}
          animating={animating}
          setAnimating={setAnimating}
          megabyte={megabyte}
          supported={true}
        />
      ) : isInstalled() ? (
        <Installed />
      ) : (
        <>
          <Main
            handleClick={handleClick}
            animating={animating}
            setAnimating={setAnimating}
            megabyte={megabyte}
            supported={false}
          />
        </>
      )}
    </div>
  );
}

export default App;
