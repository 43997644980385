export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let external_id = param.get("external_id");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let site_id = param.get("site_id");

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  setTimeout(() => {
    window.location.href =
      `https://arumo.top/hPRmm59T?external_id=${external_id}` +
      (creo_id !== null ? `&creative_id=${creo_id}` : "") +
      (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
      (site_id !== null ? `&site_id=${site_id}` : "");
  }, 200);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 12000);

  return <div></div>;
};
