export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        The Bonuses and Promotions section has been updated.
      </p>
      <p className="new__description">
        The location of the nearest ATM or Terminal has been added to the map.
      </p>
      <p className="new__description">
        Added support for Face ID and Touch ID.
      </p>
      <p className="new__description">
        Fixed an issue with push notifications that was occurring for some
        Android 10 users.
      </p>
      <p className="new__description">
        Fixed an issue where text in Settings was very small for Android users.
      </p>
    </div>
  );
};
