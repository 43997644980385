import logo from "../../assets/logo.jpg";
import item1 from "../../assets/item1.jpg";
import item2 from "../../assets/item2.jpg";
import item3 from "../../assets/item3.jpg";
import blueStar from "../../assets/starBlue.png";
import greyStar from "../../assets/starGrey.png";
import user from "../../assets/user.png";
import { useEffect, useState } from "react";
import { Reviews } from "../Reviews/Reviews";
import { New } from "../New/New";
import { Additional } from "../Additional/Additional";
import { ColorRing } from "react-loader-spinner";
// import { useReactPWAInstall } from "react-pwa-install";

export const Main = ({
  handleClick,
  animating,
  setAnimating,
  megabyte,
  supported,
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const { supported, isInstalled } = useReactPWAInstall();

  const external_id = localStorage.getItem("external_id");
  const creo_id = localStorage.getItem("creo_id");
  const ad_campaign_id = localStorage.getItem("ad_campaign_id");
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const linkToOffer = () => {
    setAnimating(true);
    setTimeout(() => {
      window.location.href =
        `https://arumo.top/hPRmm59T?external_id=${external_id}` +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    }, 80);
  };
  return (
    <main className="main">
      <div className="main__wrapper">
        <img src={logo} alt="logo" className="logo" />
        <div className="main__wrap">
          <p className="main__title">Моmobank Казино</p>
          <div className="main__tags">
            <p className="main__tag">momobank</p>
            <p className="main__tag">Casino</p>
          </div>
          <p className="years">18+</p>
        </div>
        <div className="desktop__info">
          <div className="top__wrapper">
            <img src={blueStar} alt="safety" width={14} height={14} />
            <p className="top__text">Editor's choice</p>
          </div>
          <div className="bottom__wrapper">
            <div className="greyStars__wrapper">
              <img src={greyStar} alt="grey star" width={10} height={10} />
              <img src={greyStar} alt="grey star" width={10} height={10} />
              <img src={greyStar} alt="grey star" width={10} height={10} />
              <img src={greyStar} alt="grey star" width={10} height={10} />
              <img src={greyStar} alt="grey star" width={10} height={10} />
            </div>
            <p className="bottom__text">4625</p>
            <img src={user} alt="" />
          </div>
        </div>
      </div>
      <div className="app__info">
        <ul className="app__list">
          <li className="app__item">
            <div className="app__content">
              <div className="app__wrapper">
                <p className="app__title">4.8</p>
                <svg
                  width={14}
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none">
                    <path d="M0,0h24v24H0V0z"></path>
                    <path d="M0,0h24v24H0V0z"></path>
                  </g>
                  <path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
                </svg>
              </div>
              <p className="app__description">Rating</p>
            </div>
          </li>
          <li className="app__item">
            <div className="app__content">
              <p className="app__title">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                >
                  <g transform="translate(21.552 22.5) rotate(180)">
                    <path
                      transform="translate(7.552 7.652)"
                      d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
                      data-name="Path 288"
                    ></path>
                    <g transform="translate(17.552 20.797) rotate(180)">
                      <path
                        d="M0,0H9.666V9.666H0Z"
                        fill="none"
                        data-name="Path 289"
                      ></path>
                      <path
                        transform="translate(-4.408 -3.203)"
                        d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
                        data-name="Path 290"
                      ></path>
                    </g>
                    <rect
                      transform="translate(4.552 5.5)"
                      width="16"
                      height="16"
                      rx="2"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      data-name="Rectangle 123"
                    ></rect>
                  </g>
                </svg>
              </p>
              <p className="app__description">4.7MB</p>
            </div>
          </li>
          <li className="app__item">
            <div className="app__content">
              <p className="app__title">18+</p>
              <p className="app__description">Age</p>
            </div>
          </li>
          <li className="app__item">
            <div className="app__content">
              <p className="app__title">101337</p>
              <p className="app__description">Installed</p>
            </div>
          </li>
        </ul>
      </div>
      {animating === true ? (
        <div className="lines__wrapper">
          {megabyte === "Installing..." ? (
            <p>{megabyte}</p>
          ) : (
            <p>{megabyte} MB / 4.7 MB</p>
          )}
          <div className="greyLine">
            <div className="greenLine"></div>
          </div>
        </div>
      ) : // ) : animating === "ended" ? (
      //   <button
      //     type="button"
      //     className="main__button"
      //     onClick={handleRequestPermission}
      //   >
      //     Play
      //   </button>
      animating === "done" ? (
        supported ? (
          <a href="/" target="_blank" className="main__button pwa__link">
            Play
          </a>
        ) : (
          <button type="button" className="main__button" onClick={linkToOffer}>
            Play
          </button>
        )
      ) : (
        <button
          type="button"
          className="main__button"
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <ColorRing
              visible={true}
              height="27"
              width="27"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
            />
          ) : (
            "Install"
          )}
          {/* {supported() && !isInstalled() ? "Install" : "Play"} */}
        </button>
      )}
      <div className="main__thumb">
        <img src={item1} alt="app view" height={210} className="main__images" />
        <img src={item2} alt="app view" height={210} className="main__images" />
        <img src={item3} alt="app view" height={210} className="main__images" />
      </div>
      <p className="main__about">About this application</p>
      <p className="main__item--one">
        Momobank Casino is a super application that contains a wide variety of
        games and new products in the casino world!
      </p>
      <ul
        className={
          showDescription ? "main__list" : "main__list main__list--blur"
        }
      >
        <li className="main__item">
          The most reliable and legal Casino in Ukraine in 2023!
        </li>
        {showDescription && (
          <>
            <li className="main__item">
              Instant deposit and withdrawal of funds, no commission
            </li>
            <li className="main__item">Minimum deposit is only 50 UAH</li>
            <li className="main__item">All your favorite slots in one place</li>
            <li className="main__item">Frequent tournaments and lotteries</li>
            <li className="main__item">24-hour technical support</li>
            <li className="main__item">
              The best project among all casinos in Ukraine!
            </li>
            <p
              className="main__item"
              style={{ marginLeft: "-15px", marginBottom: "20px" }}
            >
              Play in the new Casino - get easy money!
            </p>
            <p className="main__item" style={{ marginLeft: "-15px" }}>
              For new players there is a BONUS of 50,000 UAH!
            </p>
            <li className="main__item">
              Free participation in the drawing of 1,000,000 UAH!
            </li>
            <li className="main__item">
              150 FS (Free Spins) - spin the wheel of fortune
            </li>
            <li className="main__item">
              Awarding bonus points to players to pay for various services
            </li>
            <li className="main__item">
              First deposit bonus 150% - valid 30 days after registration
            </li>
          </>
        )}
      </ul>
      <button
        type="button"
        className="show__button"
        onClick={() => {
          setShowDescription((prevState) => !prevState);
        }}
      >
        {showDescription ? "Less" : "More..."}
      </button>
      <div className="line"></div>
      <Reviews />
      <div className="line"></div>
      <New />
      <div className="line"></div>
      <Additional />
      <div className="line"></div>
    </main>
  );
};
