import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Rating and reviews</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            General
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">4625</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Maxim Efremov</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">03/30/2023</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            7380 UAH poured in 5 days of play, very cool! Thanks for the awesome
            casino!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Momobank Casino</p>
            <p className="review__description">
              Maxim Efremov, thank you for your feedback! It is very important
              to us that our clients enjoy our project.
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Oleg Martynov</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">03/01/2023</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            I have no words. From 500 hryvnia I raised 7000 in a couple of hours
            ;)) I haven’t been so lucky for a long time. Best casino I've ever
            played!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Momobank Casino</p>
            <p className="review__description">
              Oleg Martynov, thank you for your positive feedback. We try to
              satisfy our customers!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Kirill Apanasenko</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">09.12.2022</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Usually I don't believe in all these casinos! But this application
            is just something! I threw in 300 hryvnia for fun and raised 3000 in
            15 minutes. I will continue to play and earn money!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Momobank Casino</p>
            <p className="review__description">
              Kirill Apanasenko, thank you for your nice review! Play and enjoy!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <div className="item__wrapper">
          <img
            src={review4}
            alt="logo"
            width={48}
            height={48}
            className="item__logo"
          />
          <div>
            <div className="item__flex">
              <div>
                <div className="item__thumb">
                  <p className="item__title">Vlad Mironov</p>
                  <div className="images__wrapper">
                    <img
                      src={starGreen}
                      alt="star green"
                      width={12}
                      height={12}
                    />
                    <img
                      src={starGreen}
                      alt="star green"
                      width={12}
                      height={12}
                    />
                    <img
                      src={starGreen}
                      alt="star green"
                      width={12}
                      height={12}
                    />
                    <img
                      src={starGreen}
                      alt="star green"
                      width={12}
                      height={12}
                    />
                    <img
                      src={starGreen}
                      alt="star green"
                      width={12}
                      height={12}
                    />
                  </div>
                </div>
                <p className="item__date">14.12.2022</p>
              </div>
              <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div>
            </div>
            <p className="item__text">
              Не ожидал, что Momobank Казино сделают такой крутой проект!
              Респект и уважение! Самое щедрое и дающее казино в моей жизни!
            </p>
            <div className="review__box">
              <div className="triangle"></div>
              <p className="review__title">Momobank Casino</p>
              <p className="review__description">
                Влад Миронов, благодарим за ваш отзыв. Наш проект был создан
                исключительно для того, чтобы дарить людям эмоции. Будем
                стараться радовать вас почаще! Удачи вам!
              </p>
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Hide reviews" : "All reviews"}
      </button>
    </>
  );
};
